<template>
  <div v-if="data">
    <json-viewer :value="parseJson(value.data)" :expand-depth="5" copyable boxed sort></json-viewer>
  </div>
</template>

<script>
import Base from './Base.vue';
import Vue from 'vue';
import { parseJson } from '../../utils';
import JsonViewer from 'vue-json-viewer';
Vue.use(JsonViewer);
export default {
  extends: Base,
  methods: {
    parseJson
  }
};
</script>

<style lang="scss">
</style>
